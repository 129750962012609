//import "./Groups.css";
//import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import useNearScreen from "../../hooks/useNearScreen";
import fetchApi from "../../services/fetchApi";
import GroupsLine from "../groups/GroupsLine";

const Groups = () => {
  const { pathname } = useLocation();

  const { show, elementRef, setOnce } = useNearScreen({
    distance: "10px",
  });

  const [list, setList] = useState({});

  useEffect(() => {
    let uri = pathname === "/home/full" ? "group/" : "group/me";
    fetchApi
      .get(`${uri}?offset=0&limit=50`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        const { groups } = res.data;

        const groupss = {};

        groups.forEach((group) => {
          const formattedName = group.serviceName
            .toLowerCase()
            .replaceAll(/\s/gi, "_");

          if (!Object.keys(groupss).includes(formattedName)) {
            // eslint-disable-next-line array-callback-return
            groupss[formattedName] = [];
            groups.forEach((item) => {
              if (
                formattedName ===
                item.serviceName.toLowerCase().replaceAll(" ", "_")
              ) {
                switch (formattedName) {
                  case "spotify_family":
                    item.serviceIcon = "/images/spotify.png";
                    break;

                  case "amazon_prime":
                    item.serviceIcon = "/images/amazon.png";
                    break;
                  case "netflix_premium":
                    item.serviceIcon = "/images/netflix.png";
                    break;

                  case "disney_plus_monthly":
                    item.serviceIcon = "/images/disney-plus.png";
                    break;
                  case "apple_music":
                    item.serviceIcon = "/images/Otros/apple-music.jpg";
                    break;

                  default:
                    item.serviceIcon = "/images/error.png";
                    break;
                }

                groupss[formattedName].push(item);
              }
            });
          }
        });
        setList(groupss);
      });
    ///.catch((e) => console.log(e?.response?.data?.error));
  }, [pathname]);

  useEffect(() => {
    if (show) {
      setOnce(false);
    }
    ///console.log(show);
  }, [show, setOnce]);

  return (
    <div className="w-full">
      {list ? (
        <>
          {Object.values(list).map((group) => {
            ///console.log("list", list);
            return (
              <div className="my-5" key={group[0]?.serviceName}>
                <p className="text-lg font-semibold capitalize">
                  {group[0]?.serviceName}
                </p>
                <GroupsLine groups={group} />
              </div>
            );
          })}
        </>
      ) : (
        <div>
          <h1>No se han encontrado resultados</h1>
        </div>
      )}
      <div ref={elementRef} />
    </div>
  );
};

export default Groups;

/* const list = {
  spotify_premium: [
    {
      id: 1,
      service: "Spotify Premium",
      serviceIcon: "/images/spotify.png",
      serviceImg: "/images/Otros/spotify.jpg",
      amount: 12,
      slots: 5,
      slotsInUse: 3,
      author: {
        name: "Carlos",
        img: "/images/avatar/1.png",
        reputation: 4,
      },
    },
    
  ],
  amazon_prime: [
    {
      id: 1,
      service: "Amazon Prime",
      serviceIcon: "/images/amazon.png",
      serviceImg: "/images/Otros/amazon-music.jpg",
      amount: 13,
      slots: 5,
      slotsInUse: 3,
      author: {
        name: "Ramirez",
        img: "/images/avatar/2.png",
        reputation: 4,
      },
    },
    
  ],
  netflix: [
    {
      id: 1,
      service: "netflix Premium",
      serviceIcon: "/images/netflix.png",
      serviceImg: "/images/Otros/netflix.jpg",
      amount: 12,
      slots: 5,
      slotsInUse: 3,
      author: {
        name: "Jose",
        img: "/images/avatar/3.png",
        reputation: 4,
      },
    },
    
  ],
  disney_plus: [
    {
      id: 1,
      service: "disney plus",
      serviceIcon: "/images/disney-plus.png",
      serviceImg: "/images/Otros/disney.jpg",
      amount: 12,
      slots: 5,
      slotsInUse: 3,
      author: {
        name: "Fabian",
        img: "/images/avatar/4.png",
        reputation: 4,
      },
    },
    
  ],
  apple_music: [
    {
      id: 1,
      service: "apple music",
      serviceIcon: "/images/apple.png",
      serviceImg: "/images/Otros/apple-music.jpg",
      amount: 12,
      slots: 5,
      slotsInUse: 3,
      author: {
        name: "Fede",
        img: "/images/avatar/5.png",
        reputation: 4,
      },
    },
    
  ],
}; */
