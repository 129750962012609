import { Link } from "react-router-dom";
import Nav from "./Nav";

const Header = () => {
  return (
    <>
      <div className="h-screen rounded-br-full bg-emerald-700 bg-gradient-to-br from-green-500 ">
        <Nav />
        <div className="grid md:grid-cols-12 gap-4">
          <div className="col-span-6 flex flex-col justify-center items-center p-6">
            <h1 className="text-gray-200 text-4xl font-semibold text-center">
              ¡Ahorre hasta un 80% en grupos!
            </h1>
            <h5 className="text-2xl text-gray-200 font-semibold md:p-11 p-4 md:m-0 my-4 text-center">
              Comparta el costo de la suscripción digital multiusuario con su
              familia, amigos y compañeros de cuarto o en el trabajo. Somos
              1,000,000 para compartir. ¡Únete a nuestra comunidad también!
            </h5>
            <Link to="/register" className="md:m-0 my-11">
              <button className="text-gray-100 rounded-lg border-emerald-300 shadow hover:text-white bg-emerald-500 p-4 text-lg font-bold">
                Registrate es gratis
              </button>
            </Link>
          </div>
          <div className="col-span-6 hidden flex-row justify-center items-center md:flex">
            <img
              src="/images/become/joiner-3.png"
              alt="joiner"
              className="object-scale-down w-full p-4"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
