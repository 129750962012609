import Joi from "joi";
import { useState } from "react";
import { IoLogoWhatsapp } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { registerAction } from "../../actions/registerActions";
import RegisterFacebook from "./RegisterFacebook";
import RegisterGoogle from "./RegisterGoogle";

const emailErrors = Joi.string()
  .not()
  .empty()
  .email({ tlds: { allow: false } });

const passwordErrors = Joi.string().not().empty();
const firstNameErrors = Joi.string().not().empty();
const lastNameErrors = Joi.string().not().empty();

const RegisterForm = () => {
  //#region States
  const [view, setView] = useState(0);
  const [fieldsError, setFieldsError] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateBirth, setDateBirth] = useState("");
  const [countryResidence, setCountryResidence] = useState("");
  const [states, setStates] = useState("");
  const [currentCity, setCurrentCity] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  //#endregion States

  const state = useSelector((state) => state.register);

  const dispatch = useDispatch();
  const dispatchRegister = (data) => dispatch(registerAction(data));

  const verifyFields = (field) =>
    ({
      firstName() {
        const notValidFirstName = firstNameErrors.validate(firstName);
        if (notValidFirstName.error) return setFirstNameError(true);
        setFirstNameError(false);
      },
      lastName() {
        const notValidLastName = lastNameErrors.validate(lastName);
        if (notValidLastName.error) return setLastNameError(true);
        setLastNameError(false);
      },

      email() {
        const notValidEmail = emailErrors.validate(email);
        if (notValidEmail.error) return setEmailError(true);
        setEmailError(false);
      },

      password() {
        const notValidPassword = passwordErrors.validate(password);
        if (notValidPassword.error) return setPasswordError(true);
        setPasswordError(false);
      },
    }[field]());

  const register = (e) => {
    e.preventDefault();
    if (emailError || firstNameError || lastNameError || passwordError)
      return setFieldsError(true);

    if (view === 0) {
      setView(1);
      return;
    }

    if (
      emailError ||
      firstNameError ||
      lastNameError ||
      passwordError ||
      !dateBirth.trim() ||
      !countryResidence.trim() ||
      !states.trim() ||
      !currentCity.trim()
    )
      return setFieldsError(true);

    setFieldsError(false);
    dispatchRegister({
      firstName,
      lastName,
      email,
      password,
      dateBirth,
      countryResidence,
      state: states,
      currentCity,
    });
  };

  return (
    <div className="p-4">
      {fieldsError ? (
        <div className="border-l-4 border-red-600 bg-red-400 m-2">
          <button
            className="border-none border text-white font-bold text-lg px-4"
            onClick={setView(0)}
          >
            Atras
          </button>
          <p className="p-4 text-center text-white font-bold flex flex-col">
            Error:
            <span className="text-gray-100 font-semibold">
              Porfavor llena los campos correctamente
            </span>
          </p>
        </div>
      ) : null}
      {state.error.message ? (
        <div className="border-l-4 border-red-600 bg-red-400 m-2">
          <button
            className="border-none border text-white font-bold text-lg px-4"
            onClick={setView(0)}
          >
            Atras
          </button>
          <p className="p-4 text-center text-white font-bold flex flex-col">
            Error:
            <span className="text-gray-100 font-semibold">
              {state.error.message}
            </span>
          </p>
        </div>
      ) : null}
      {state.registerSuccess ? (
        <div className="border-l-4 border-green-600 bg-green-400 m-2">
          <p className="p-4 text-center text-white font-bold flex flex-col">
            Completado:
            <span className="text-gray-100 font-semibold">
              Se ha registrado correctamente, Porfavor inicia sesion
            </span>
          </p>
        </div>
      ) : null}
      <h1 className="text-gray-800 font-medium text-3xl text-center">
        Crea una cuenta
      </h1>
      <div className="border-b-2 border-emerald-100 w-full my-4 " />
      {/* <RegisterGoogle />
      <RegisterFacebook /> */}
      <form onSubmit={(e) => register(e)} className="w-full flex flex-col">
        <div
          style={{
            width: "100%",
            height: 20,
            borderBottom: "1.5px solid #999",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontSize: 18,
              backgroundColor: "#FAFAFA",
              padding: "0 10px",
            }}
          >
            o
          </span>
        </div>

        {view === 0 ? (
          <div>
            <div className="flex flex-row gap-4">
              <div className="flex-column">
                <input
                  type="text"
                  placeholder="Nombre"
                  className={`focus:border-emerald-400 hover:border-emerald-400  focus:outline-none w-full border-b p-3 m-2 ${
                    firstNameError ? "border-red-600" : ""
                  }`}
                  autoFocus
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  onBlur={() => verifyFields("firstName")}
                />
                {firstNameError ? (
                  <p className="text-center">
                    <span className="text-red-500 text-sm">
                      Porfavor ingresa tu nombre correctamente
                    </span>{" "}
                  </p>
                ) : null}
              </div>
              <div className="flex-column">
                <input
                  type="text"
                  placeholder="Apellidos"
                  className={`focus:border-emerald-400 hover:border-emerald-400  focus:outline-none w-full border-b p-3 m-2 ${
                    lastNameError ? "border-red-600" : ""
                  }`}
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  onBlur={() => verifyFields("lastName")}
                />
                {lastNameError ? (
                  <p className="text-center">
                    <span className="text-red-500 text-sm">
                      Porfavor ingresa tu apellido correctamente
                    </span>{" "}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="flex flex-col">
              <input
                type="email"
                onBlur={() => verifyFields("email")}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                placeholder="Direccion Email"
                className={`focus:border-emerald-400 hover:border-emerald-400  focus:outline-none w-full border-b p-3 m-2 ${
                  emailError ? "border-red-600" : ""
                }`}
              />
              {emailError ? (
                <p className="text-center">
                  <span className="text-red-500 text-sm">
                    Porfavor ingresa un email valido
                  </span>{" "}
                </p>
              ) : null}
              <input
                type="password"
                placeholder="Password"
                className={`focus:border-emerald-400 hover:border-emerald-400  focus:outline-none w-full border-b p-3 m-2 ${
                  passwordError ? "border-red-600" : ""
                }`}
                onChange={(e) => setPassword(e.target.value)}
                onBlur={() => verifyFields("password")}
              />
              {passwordError ? (
                <p className="text-center">
                  <span className="text-red-500 text-sm">
                    Porfavor ingresa una contraseña valida
                  </span>{" "}
                </p>
              ) : null}
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-row gap-4 mt-5">
              <div className="flex flex-col">
                <label>Fecha de nacimiento: </label>
                <input
                  type="date"
                  onChange={(e) => setDateBirth(e.target.value)}
                  className="focus:border-emerald-400 hover:border-emerald-400  focus:outline-none w-full border-b p-3 m-2 "
                />
              </div>
              <div className="flex flex-col">
                <label>Pais de residencia:</label>
                <input
                  type="text"
                  onChange={(e) => setCountryResidence(e.target.value)}
                  className="focus:border-emerald-400 hover:border-emerald-400  focus:outline-none w-full border-b p-3 m-2 "
                  placeholder="Pais de residencia actual"
                />
              </div>
            </div>
            <div className="flex flex-row gap-4 mt-5">
              <div className="flex flex-col">
                <label>Estado de residencia:</label>
                <input
                  type="text"
                  onChange={(e) => setStates(e.target.value)}
                  className="focus:border-emerald-400 hover:border-emerald-400  focus:outline-none w-full border-b p-3 m-2 "
                  placeholder="Estado de residencia actual"
                />
              </div>
              <div className="flex flex-col">
                <label>Ciudad de residencia:</label>
                <input
                  type="text"
                  onChange={(e) => setCurrentCity(e.target.value)}
                  className="focus:border-emerald-400 hover:border-emerald-400  focus:outline-none w-full border-b p-3 m-2 "
                  placeholder="Ciudad de residencia actual"
                />
              </div>
            </div>
          </>
        )}
        <input
          type="submit"
          value="Crear cuenta"
          className="my-4 focus:outline-none bg-emerald-500 text-white font-bold p-4 rounded-md cursor-pointer hover:bg-emerald-600"
        />
        <p>
          ¿Ya tienes una cuenta?
          <Link to="/login">
            {" "}
            <span className="text-emerald-500 font-normal hover:text-emerald-500">
              Accede
            </span>
          </Link>
        </p>
        <button className="text-white my-4 focus:outline-none bg-green-500 rounded-md font-semibold flex justify-center items-center p-3">
          <IoLogoWhatsapp className="mx-3" /> WhatsApp
        </button>
      </form>
    </div>
  );
};

export default RegisterForm;
