import { useEffect, useState } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";

import fetchApi from "../services/fetchApi";

const Create = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    fetchApi("service/names").then((res) => {
      const data = res.data.services.map((service) => {
        const formattedService = { ...service };
        formattedService.slug = service.name.toLowerCase().replaceAll(" ", "_");
        return formattedService;
      });
      setServices(data);
      //setServices(res.data.services)
    });
  }, []);

  return (
    <>
      <h3 className="text-center">
        Selecciona el servicio deseado de la lista a continuación. Si no lo
        encuentras, selecciona 'Personalizar'
      </h3>
      <div className="w-1/2 mx-auto my-5">
        {/* <Link to="/create/custom">
          <div className="bg-white border-gray-200 shadow rounded-md p-5 cursor-pointer">
            <div className="flex justify-between items-center">
              <p className="font-semibold text-gray-600 text-lg">
                Personalizar
              </p>
              <p className="text-emerald-400 text-xl">
                <AiOutlineRight className="text-xl" />
              </p>
            </div>
          </div>
        </Link> */}
        <div className="grid grid-cols-2 gap-4 mt-5 w-full">
          {services.map((item) => (
            <Link to={`/create/${item.slug}/${item.id}`} key={item.id}>
              <div className="bg-white border-gray-200 shadow rounded-md p-5 cursor-pointer">
                <div className="flex justify-between items-center">
                  <p className="font-semibold text-gray-600 text-lg">
                    {item.name}
                  </p>
                  <p className="text-emerald-400 text-xl">
                    <AiOutlineRight className="text-xl" />
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Create;
