import { registerTypes } from "../types";
const { DEPOSIT, DEPOSIT_ERROR, DEPOSIT_SUCCESS } = registerTypes;

const initialState = {
  id: null,
  saldo: null,
  disponible: null,
  loading: false,
  error: false,
  depositSuccess: false,
};

export default function walletReducer(state = initialState, { type, payload }) {
  switch (type) {
    case DEPOSIT:
      return {
        ...state,
        loading: payload,
        depositSuccess: false,
      };
    case DEPOSIT_SUCCESS:
      return {
        ...payload,
        loading: false,
        error: false,
        depositSuccess: true,
      };
    case DEPOSIT_ERROR:
      return {
        ...state,
        error: { ...payload },
        loading: false,
        depositSuccess: false,
      };
    default:
      return state;
  }
}
