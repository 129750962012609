import { useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import useAuth from "../../hooks/useAuth";

const HOC = ({ children }) => {
  const { pathname } = useLocation();

  const { isLogged } = useAuth(pathname);
  if (isLogged) {
    return children;
  } else {
    return <Redirect to="/login" />;
  }
};

export default HOC;
