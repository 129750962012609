import { memo, useCallback, useEffect, useRef, useState } from "react";
import { fetchApi } from "../../services";

let PaypalC = memo(({ amount, setCheckout, setAmount, setWallet }) => {
  const paypal = useRef();

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder(data, actions, err) {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                amount: {
                  currency_code: "USD",
                  value: amount,
                },
                description: "Compra de fondos ES2",
                name: "fondos",
              },
            ],
          });
        },
        async onApprove(data, actions) {
          const order = await actions.order.capture();
          const cost = parseFloat(order.purchase_units[0].amount.value);
          console.log(cost);
          fetchApi
            .put(
              "monedero/",
              {
                saldo: cost,
                tipo_movimiento: "abono",
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            )
            .then((res) => {
              console.log(res.data);
              setWallet(res.data.Monedero);
              setAmount(null);
            })
            .catch((e) => console.log(e.response.data));
        },
        onError(err) {
          console.log(err);
        },
      })
      .render(paypal.current);
  }, [amount, setAmount, setCheckout, setWallet]);

  return (
    <>
      <div ref={paypal} />
    </>
  );
});

const Paypal = ({ amount, setAmount, setWallet }) => {
  const [checkout, setCheckout] = useState(false);

  useEffect(() => setCheckout(false), [amount]);

  return (
    <>
      {checkout ? (
        <PaypalC
          amount={amount}
          setCheckout={setCheckout}
          setAmount={setAmount}
          setWallet={setWallet}
        />
      ) : (
        <button
          className="focus:outline-none text-white p-4 md:w-2/5 font-semibold rounded bg-emerald-500 text-center my-3"
          onClick={() => setCheckout(true)}
        >
          Proceder al pago con paypal
        </button>
      )}
    </>
  );
};

export default Paypal;

/*
const Paypal = ({ order }) => {
  const paypalConf = {
    currency: "USD",
    env: "sandbox",
    client: {
      sandbox:
        "AfnO8_x50NJKWxZ-OGf1pT9T9-2g90pWZIHGygiQ1rAeBf4Ua5QQ95ZHqcZRfKVvzlVQV8s50Bgbzb_2",
      production: "--id--",
      secret:
        "EOAEds6TvvTJ76nxPSqbznqgQHocoyJe6pELPuvig7DG5zhbSW1WozmH4EoZa9OWnOc1IMF4dGgarc5M",
    },
    style: {
      label: "pay",
      size: "medium",
      shape: "rectangular",
      color: "gold",
    },
  };

  const PaypalBtn = paypal.Button.driver("react", { React, ReactDOM });

  const payment = async (data, actions) => {
    console.log(actions);
    const payment = {
      transactions: [
        {
          amount: {
            total: order.total,
            currency: paypalConf.currency,
          },
          description: "Description example",
          custom: order.customer || "",
          item_list: {
            items: order.items,
          },
        },
      ],
      note_to_payer: "Nota de pago",
    };
    let v = await actions.order.create({ payment });
    console.log(v);
  };

  const onAuthorize = (data, actions) => {
    console.log("auth");
    return actions.payment
      .execute()
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const onError = (error) => {
    console.log(error);
  };

  const onCancel = (data, actions) => {
    alert("Payment cancelate");
  };

  return (
    <>
      <PaypalBtn
        env={paypalConf.env}
        client={paypalConf.client}
        payment={(data, actions) => payment(data, actions)}
        onAuthorize={(data, actions) => onAuthorize(data, actions)}
        onCancel={(data, actions) => onCancel(data, actions)}
        onError={(error) => onError(error)}
        onApprove={(d) => console.log(d)}
        locale="es_MX"
      />
    </>
  );
}; */
