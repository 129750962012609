import RegisterForm from "../components/register/RegisterForm";

const Register = () => {
  return (
    <>
      <div className="grid md:grid-cols-12">
        <div className="md:col-span-4 h-screen md:w-full flex flex-col justify-center items-center">
          <div className="flex flex-col justify-center items-center">
            <RegisterForm />
          </div>
        </div>
        <div className="md:col-span-8 h-screen w-full">
          <div className="bg-logins h-screen flex flex-col justify-center items-center text-center">
            <div className="my-6">
              <h1 className="text-white font-bold text-6xl">
                Registrate, es gratis
              </h1>
              <p className="text-white font-medium text-4xl mt-4 p-4">
                Crea tu cuenta crea una en ES2 y empieza a compartir tus
                servicios gratis
              </p>
            </div>
            <div>
              <img
                src="/images/logos/logo.png"
                alt="bg"
                className="flex object-scale-down h-48 w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
