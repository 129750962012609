import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import reputation from "../components/groups/Reputation";
import fetchApi from "../services/fetchApi";
import formatNumbers from "../services/formatNumbers";

const Post = () => {
  const { service, id } = useParams();
  const history = useHistory();

  const [group, setGroup] = useState({});

  useEffect(() => {
    fetchApi
      .get(`group/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data.groupInfo);
        const date = new Date(Date.parse(res.data.groupInfo.createdAt));
        res.data.groupInfo.createdAt = date
          .toLocaleString("es-MX")
          .substr(0, 10);
        //.replace(/([0-9][0-9]:[0-9][0-9]:[0-9][0-9]\s)\w+/gi, "");
        setGroup(res.data.groupInfo);
      });
  }, [id]);

  const joinGroup = () => {
    // fetchApi
    //   .put(
    //     "monedero/",
    //     {
    //       monto_cargo: group?.joiningCost,
    //       tipo_movimiento: "cargo",
    //     },
    //     {
    //       headers: {
    //         Authorization: `Bearer ${localStorage.getItem("token")}`,
    //       },
    //     }
    //   )
    //   .then((res) => {
    //     console.log(res);
    //   });

    history.push(`/post/${service}/${id}/checkout`);
  };

  return (
    <>
      <div className="grid  md:grid-cols-12 w-4/5 p-4 gap-3 mx-auto min-h-screen mb-6">
        <div className="md:col-span-4 bg-white">
          <div className="flex flex-col justify-center items-center w-full">
            <img
              src={
                group?.imageUrl && group?.imageUrl !== ""
                  ? group?.imageUrl
                  : "/images/error.png"
              }
              alt="service"
              className="object-scale-down w-full"
            />
            <div className="absolute rounded-lg items-center p-4 justify-center">
              <img
                src={
                  group?.admin?.profileImageUrl
                    ? group?.admin?.profileImageUrl
                    : "/images/avatar/1.png"
                }
                alt=""
                className="object-scale-down w-20 rounded-full bg-emerald-500 border border-white border-double"
              />
            </div>
            <div className="mt-10">
              <p className="text-center text-lg font-bold text-gray-600">
                {group?.admin?.fullName}
              </p>
              <div className="flex md:flex-row flex-col mt-10 items-center">
                <p>Reputacion</p>
                <div className="flex">
                  {reputation(group?.admin?.trustLevel)}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center my-8">
            <p className="text-2xl">Joiners actuales</p>
            {group?.joiners?.length >= 1 ? (
              <>
                {group.joiners.map((joiner) => (
                  <div className="flex flex-col border border-emerald-400 rounded-lg my-5 p-4">
                    <div className="flex flex-row justify-center items-center gap-4">
                      <div>
                        <img
                          src={
                            joiner?.profileImageUrl &&
                            joiner?.profileImageUrl !== ""
                              ? joiner?.profileImageUrl
                              : "/images/avatar/1.png"
                          }
                          alt="service"
                          className="object-scale-down w-12 rounded-full"
                        />
                      </div>
                      <p>{joiner?.fullName}</p>
                    </div>
                    <div className="flex flex-row justify-center items-center mt-1">
                      {reputation(joiner?.trustLevel)}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <p>No hay joiners en este grupo aun</p>
            )}
          </div>
        </div>

        <div className="md:col-span-8 p-4 bg-white">
          <div className="flex align-center justify-between">
            <div className="flex flex-col items-center justify-center">
              <h1 className="font-semibold text-3xl capitalize text-gray-700">
                {group?.serviceName}
              </h1>
              <p>{group?.serviceDescription}</p>
            </div>
            <div className="flex flex-col">
              <h1 className="font-semibold  text-3xl capitalize text-gray-700">
                {group?.freeSlots}/{group?.totalSlots}
              </h1>
              <p>Puestos Libres</p>
            </div>
          </div>
          <div className="flex align-center justify-between mt-4">
            <div className="flex flex-col">
              <h1 className="text-lg text-gray-700">Periodo de facturacion</h1>
              <p>Grupo creado el</p>
            </div>
            <div className="flex flex-col">
              <p className="text-lg text-emerald-500">
                {group?.renewalFrequency}
              </p>
              <p className="text-lg text-emerald-500">{group?.createdAt}</p>
            </div>
          </div>
          <hr className="my-5" />
          <div className="flex align-center justify-between mt-4">
            <h1 className="font-semibold text-lg text-gray-700">Total</h1>
            <p className="text-lg font-bold text-emerald-500">
              {formatNumbers.format(group?.joiningCost)}
            </p>
          </div>
          <button onClick={() => joinGroup()}>
            <button className="bg-emerald-500 hover:bg-emerald-600 p-3 my-5 w-full text-white text-lg font-semibold rounded-md">
              Entrar en el grupo
            </button>
          </button>
        </div>
      </div>
    </>
  );
};

export default Post;
