//import { Link } from "react-router-dom";
import Header from "../components/home/Header";
import Plans from "../components/home/Plans";

const Home = () => {
  return (
    <>
      <Header />
      <div className="mx-auto w-5/6 my-5 p-4">
        <Plans />
      </div>
    </>
  );
};

export default Home;
