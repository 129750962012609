import { loginTypes } from "../types";
const { LOGIN, LOGIN_ERROR, LOGIN_SUCCESS } = loginTypes;

const initialState = {
  email: "",
  password: "",
  isLogged: false,
  loading: false,
  error: false,
};

export default function loginReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        loading: payload,
      };

    case LOGIN_SUCCESS:
      return {
        ...payload,
        error: false,
        loading: false,
        isLogged: true,
      };

    case LOGIN_ERROR:
      return {
        ...state,
        error: { ...payload },
        loading: false,
        isLogged: false,
      };

    default:
      return state;
  }
}
