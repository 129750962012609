import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Paypal from "../components/payments/Paypal";

const PostPayment = () => {
  const [amount, setAmount] = useState(0);
  const [wallet, setWallet] = useState({});
  const { id } = useParams();

  const authToken = window.localStorage.getItem("token");

  const getGroup = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API}group/${id}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    setAmount(res.data.groupInfo.joiningCost);
  };

  const getWallet = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API}monedero/me`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    setWallet(res.data);
  };

  const payment = async () => {
    if (wallet.disponible >= amount) {
      const res = await axios.put(
        `${process.env.REACT_APP_API}monedero/`,
        {
          monto_cargo: amount,
          tipo_movimiento: "cargo",
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (res.status === 200) {
        const joined = await axios.post(
          `${process.env.REACT_APP_API}group/${id}/join/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        console.log(joined);
      }

      console.log(res);
    } else {
      console.log("no");
    }
  };

  useEffect(() => {
    getGroup();
    getWallet();
  }, []);

  // const order = {
  //   customer: "12",
  //   amount: 2.0,
  //   items: [
  //     {
  //       sku: "Idgroup",
  //       name: "spotify",
  //       price: "2.00",
  //       quantity: 2,
  //       currency: "USD",
  //     },
  //     {
  //       sku: "Idgroup2",
  //       name: "netflix",
  //       price: "4.00",
  //       quantity: 1,
  //       currency: "USD",
  //     },
  //   ],
  // };

  return (
    <>
      <div className="grid md:grid-cols-12 w-4/5 p-4 gap-3 mx-auto min-h-screen mb-6">
        <div className="md:col-span-8 bg-white p-4 rounded">
          <h3 className="text-gray-700 text-3xl font-semibold mb-3 uppercase">
            Entra en el grupo!
          </h3>
          <hr />

          <Paypal amount={amount} setAmount={setAmount} setWallet={setWallet} />

          <button className="p-5 bg-emerald-500 text-white" onClick={payment}>
            Pagar
          </button>

          <hr className="my-3" />
          <Link to="#s">
            <button className="my-4 bg-emerald-600 focus:outline-none font-semibold rounded-md px-4 py-1 text-xl text-white">
              Continua
            </button>
          </Link>
        </div>
        <div className="md:col-span-4 bg-white p-4 rounded border border-gray-200"></div>
      </div>
    </>
  );
};

export default PostPayment;
