import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import registerReducer from "./registerReducer";
import walletReducer from "./walletReducer";

export default combineReducers({
  login: loginReducer,
  register: registerReducer,
  wallet: walletReducer,
});
