import { registerTypes } from "../types";
const { REGISTER, REGISTER_ERROR, REGISTER_SUCCESS } = registerTypes;

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  dateBirth: "",
  countryResidence: "",
  state: "",
  currentCity: "",
  loading: false,
  error: false,
  registerSuccess: false,
};

export default function registerReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case REGISTER:
      return {
        ...state,
        loading: payload,
        registerSuccess: false,
      };
    case REGISTER_SUCCESS:
      return {
        ...payload,
        loading: false,
        error: false,
        registerSuccess: true,
      };
    case REGISTER_ERROR:
      return {
        ...state,
        error: { ...payload },
        registerSuccess: false,
      };
    default:
      return state;
  }
}
