import axios from "axios";
import FacebookLogin from "react-facebook-login";
import { IoLogoFacebook } from "react-icons/io";
import CORS from "cors";

CORS();

const LoginFacebook = () => {
  const fbResponse = async (response) => {
    console.log("Login");
    try {
      let res = await axios.get(
        "https://d9523fff47c3.ngrok.io/api/auth/facebook/",
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="w-full flex flex-column justify-center my-3">
      {/* <button
        className="p-3 bg-blue-400 text-white font-semibold"
        onClick={() => fbResponse()}
      >
        Login FB
      </button>
      <a href="https://d9523fff47c3.ngrok.io/api/auth/facebook/" target="blank">
        Open
      </a> */}
      <FacebookLogin
        cssClass=" flex items-center justify-center text-lg bg-blue-600 font-semibold text-white text-center p-3 rounded-md"
        icon={<IoLogoFacebook className="mx-3 text-xl" />}
        appId="480857696597602"
        fields="name,email,picture,email"
        callback={fbResponse}
      />
    </div>
  );
};

export default LoginFacebook;
