import ScrollMenu from "react-horizontal-scrolling-menu";
import reputation from "./Reputation";

import { useHistory } from "react-router-dom";

export const Menu = (groups) => {
  return groups?.map((item) => (
    <div
      key={item.id}
      className="w-64 mx-2 h-40 border focus:outline-none focus-within:outline-none appearance-none flex flex-row justify-between border-gray-100 rounded-lg cursor-pointer ps-3 dbg-emerald-400 bg-opacity-75 overflow-hidden"
    >
      <img
        src={item.imageUrl ? item.imageUrl : "/images/error.png"}
        alt=""
        className="object-cover cover-fill w-auto rounded-md overflow-hidden"
      />
      <div className="flex flex-col absolute justify-between gap-3 p-4 bg-gray-900 bg-opacity-80 h-40 rounded-md w-64 overflow-hidden">
        <div className="col-span-6 flex row items-center">
          <div className="flex flex-col">
            <p className="font-semibold text-white mr-9 uppercase">
              {item.serviceName}
            </p>
            <p className="text-white">
              <span className="font-semibold text-white">
                {item.joiningCost}
              </span>{" "}
              $ per month
            </p>
            <p className="text-white">
              <span className="font-semibold text-white">{item.freeSlots}</span>
              /{item.totalSlots} espacios libres
            </p>
          </div>
          <img
            src={item.serviceIcon}
            alt={item.serviceName}
            className="object-cover cover-full w-16 mx-auto bg-white rounded-full p-1"
          />
        </div>
        <div className="col-span-6 flex row items-center">
          <img
            src={item.admin.profileImageUrl}
            alt={item.admin.fullName}
            className="object-scale-down w-11 rounded-full bg-emerald-800"
          />
          <p className="ml-4 text-white">{item.admin.fullName}</p>
          {reputation(item.admin.rating)}
        </div>
      </div>
    </div>
  ));
}

const GroupsLine = ({ groups }) => {
  const menu = Menu(groups);
  const { push } = useHistory();
  return (
    <>
      <div className="my-3 w-full mx-auto">
        <ScrollMenu
          data={menu}
          onSelect={(id) =>
            push(
              `/post/${groups[0]?.serviceName
                .toLowerCase()
                .replaceAll(" ", "_")}/${id}`
            )
          }
          wrapperStyle={{
            width: "100%",
          }}
          itemStyle={{
            border: "none",
            outline: "none",
          }}
          selected={(e) => console.log(e)}
        />
      </div>
    </>
  );
};

export default GroupsLine;
