import { Link, useParams } from "react-router-dom";

const PostCheckout = () => {
  const { service, id } = useParams();
  return (
    <>
      <div className="grid md:grid-cols-12 w-4/5 p-4 gap-3 mx-auto min-h-screen mb-6">
        <div className="md:col-span-8 bg-white p-4 rounded">
          <h3 className="text-gray-700 text-3xl font-semibold mb-3 uppercase">
            Entra en el grupo!
          </h3>
          <hr />
          <p className="text-gray-600 font-semibold">
            Declara la relación que tienes con el admin para participar al grupo
            compartido
          </p>

          <div className="mt-2 flex flex-col w-full">
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                className="form-radio"
                name="accountType"
                value="friends"
                disabled
              />
              <span className="ml-2">Amigos</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                className="form-radio"
                name="accountType"
                value="family"
              />
              <span className="ml-2">Familia</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                className="form-radio"
                name="accountType"
                value="domestic"
              />
              <span className="ml-2">Mismo nucleo domestico</span>
            </label>
            <label className="inline-flex items-center ml-6">
              <input
                type="radio"
                className="form-radio"
                name="accountType"
                value="job"
                disabled
              />
              <span className="ml-2">Amigo de trabajo</span>
            </label>
          </div>
          <hr className="my-3" />
          <div>
            <label className="inline-flex items-center">
              <span className="text-gray-600 font-semibold text-sm">
                Confirmo que entiendo que ES2 no está asociado o afiliado a
                Netflix Premium y que he leído, entendido y acepto cumplir con
                los términos y condiciones para compartir Netflix Premium.
              </span>
              <input type="checkbox" className="form-checkbox" />
            </label>
          </div>
          <Link to={`/post/${service}/${id}/checkout/success`}>
            <button className="my-4 bg-emerald-600 focus:outline-none font-semibold rounded-md px-4 py-1 text-xl text-white">
              Continua
            </button>
          </Link>
        </div>
        <div className="md:col-span-4 bg-white p-4 rounded border border-gray-200"></div>
      </div>
    </>
  );
};

export default PostCheckout;
