import { registerTypes } from "../types";
const { REGISTER, REGISTER_ERROR, REGISTER_SUCCESS } = registerTypes;

export function registerAction(data) {
  return async (dispatch, _getState, { fetchApi }) => {
    dispatch(register(true));
    try {
      console.log(data);
      const res = await fetchApi.post("/auth/signup", data);
      console.log(res);
      dispatch(registerSuccess(data));
    } catch (e) {
      console.log(e.response.data.error);
      dispatch(registerError(e.response.data.error));
    }
  };
}

const register = (payload) => ({
  type: REGISTER,
  payload,
});

const registerSuccess = (payload) => ({
  type: REGISTER_SUCCESS,
  payload,
});

const registerError = (payload) => ({
  type: REGISTER_ERROR,
  payload,
});
