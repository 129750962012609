// import Categories from "../components/homefull/Categories";
import Groups from "../components/homefull/Groups";
import ServicesList from "../components/services/ServicesList";

const HomeFull = () => {
  return (
    <>
      <div className="container mx-auto p-4 min-h-screen">
        {/* <Categories /> */}
        {/* <Groups /> */}

        <ServicesList />
      </div>
    </>
  );
};

export default HomeFull;
