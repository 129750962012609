import { useEffect, useState } from "react";
import Deposit from "../components/wallet/Deposit";
import { fetchApi } from "../services";
import formatNumbers from "../services/formatNumbers";

const Wallet = () => {
  const [wallet, setWallet] = useState({});

  useEffect(() => {
    fetchApi
      .get("/monedero/me", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setWallet(res.data);
      });
  }, []);

  return (
    <>
      <div className="mx-3">
        <div className="flex flex-col md:flex-row justify-center gap-3 w-full mb-5">
          <div className="flex flex-col bg-blue-400 border-l-4 p-4 w-full md:w-1/3 mx-auto text-center border-blue-700 bg-opacity-75">
            <h1 className="text-3xl text-gray-900 font-medium">
              {wallet?.saldo ? formatNumbers.format(wallet.saldo) : "$0.00"}
            </h1>
            <p className="text-lg text-gray-600 font-semibold">
              Fondos Totales
            </p>
          </div>
          <div className="flex flex-col bg-green-400 border-l-4 p-4 w-full md:w-1/3 mx-auto text-center border-green-700 bg-opacity-75">
            <h1 className="text-3xl text-gray-900 font-medium">
              {wallet?.disponible
                ? formatNumbers.format(wallet.disponible)
                : "$0.00"}
            </h1>
            <p className="text-lg text-gray-600 font-semibold">
              Fondos Disponibles
            </p>
          </div>
        </div>
      </div>
      <div className="grid md:grid-cols-12 gap-3 mx-3 p-4">
        <Deposit setWallet={setWallet} />
      </div>
    </>
  );
};

export default Wallet;
