const reputation = (points) => {
  points = parseInt(points?.toString()[0]);
  switch (points) {
    case 1:
      return (
        <>
          <img
            src="/images/star-full.png"
            className="object-scale-down w-5 ml-3"
            alt=""
          />
          <img
            src="/images/star.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
          <img
            src="/images/star.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
          <img
            src="/images/star.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
          <img
            src="/images/star.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
        </>
      );

    case 2:
      return (
        <>
          <img
            src="/images/star-full.png"
            className="object-scale-down w-5 ml-3"
            alt=""
          />
          <img
            src="/images/star-full.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
          <img
            src="/images/star.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
          <img
            src="/images/star.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
          <img
            src="/images/star.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
        </>
      );

    case 3:
      return (
        <>
          <img
            src="/images/star-full.png"
            className="object-scale-down w-5 ml-3"
            alt=""
          />
          <img
            src="/images/star-full.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
          <img
            src="/images/star-full.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
          <img
            src="/images/star.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
          <img
            src="/images/star.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
        </>
      );

    case 4:
      return (
        <>
          <img
            src="/images/star-full.png"
            className="object-scale-down w-5 ml-3"
            alt=""
          />
          <img
            src="/images/star-full.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
          <img
            src="/images/star-full.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
          <img
            src="/images/star-full.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
          <img
            src="/images/star.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
        </>
      );

    case 5:
      return (
        <>
          <img
            src="/images/star-full.png"
            className="object-scale-down w-5 ml-3"
            alt=""
          />
          <img
            src="/images/star-full.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
          <img
            src="/images/star-full.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
          <img
            src="/images/star-full.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
          <img
            src="/images/star-full.png"
            className="object-scale-down w-5 ml-1"
            alt=""
          />
        </>
      );

    default:
      break;
  }
};

export default reputation;
