import { Link, useHistory, withRouter } from "react-router-dom";
import { RiAccountPinCircleLine } from "react-icons/ri";
import { GiPresent } from "react-icons/gi";
import { IoWalletOutline } from "react-icons/io5";
//import { AiOutlineSearch } from "react-icons/ai";
import { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { useSelector } from "react-redux";
import fetchApi from "../../services/fetchApi";

const NavBar = ({ location }) => {
  //TODO btn hide navbar mobile display
  const [showNav, setShowNav] = useState(false);
  //const [expandSearch, setExpandSearch] = useState(false);
  const [showBar, setShowBar] = useState(false);
  //  const { location } = useHistory();

  //const wallet = useSelector((state) => state.wallet);
  const [wallet, setWallet] = useState({});

  useEffect(() => {
    //console.log(location);
    if (
      location.pathname === "/" ||
      location.pathname === "/login" ||
      location.pathname === "/register"
    )
      return setShowBar(false);
    return setShowBar(true);
  });

  useEffect(() => {
    fetchApi
      .get("/monedero/me", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        //console.log(res.data);
        setWallet(res.data);
      });
  }, [location]);

  return (
    <>
      {showBar ? (
        <div className="bg-white w-full h-auto p-4 mb-5  shadow z-50">
          <div className="flex flex-col md:flex-row items-center justify-between gap-4">
            <div className="flex gap-4 w-full">
              <Link to="/home/full">
                <img
                  src="/images/logos/Logo.png"
                  alt="logo"
                  className="flex object-scale-down w-12"
                />
              </Link>
              {/* <div className="flex items-center shadow-md p-2 gap-3 border-gray-100 border">
              <label htmlFor="search">
                <AiOutlineSearch className="text-emerald-500 text-lg" />
              </label>
              <input
                name="search"
                id="search"
                type="text"
                placeholder="Busca entre los grupos"
                className="focus:outline-none placeholder-emerald-500 font-normal h-full text-emerald-500 w-full"
              />
            </div> */}
              <div className="md:hidden flex justify-end flex-row items-center">
                <button onClick={() => setShowNav((pv) => !pv)}>
                  <GiHamburgerMenu className=" text-gray-800 text-2xl" />
                </button>
              </div>
            </div>
            <div
              className={`items-center flex-col md:flex-row transition-opacity gap-4 ${
                showNav ? "flex" : "hidden md:flex"
              }`}
            >
              <Link className="text-gray-500 mx-2 font-medium" to="/create">
                Crear grupo
              </Link>
              <Link
                className="mx-2 font-medium text-emerald-500"
                to="/home/full"
              >
                Network
              </Link>
              <Link className="text-gray-500 mx-2 font-medium" to="/sharing">
                Mis grupos
              </Link>
              <div className=" md:visible hidden border-l border-gray-500 h-11 mx-2" />
              <div className="flex flex-row items-center justify-center gap-4">
                <Link to="/">
                  <RiAccountPinCircleLine className="text-3xl text-gray-500" />
                </Link>
                <Link to="/giftcards">
                  <GiPresent className="text-3xl text-gray-500" />
                </Link>
                <Link
                  to="/wallet"
                  className="flex items-center justify-center gap-2"
                >
                  {/* <IoMdNotificationsOutline className="text-3xl text-gray-500" /> */}
                  <IoWalletOutline className="text-3xl text-gray-500" />
                  <p className="text-gray-600 text-center">
                    {`$${wallet.disponible}`}
                  </p>
                </Link>
                {/* <Link to="/">
                <img
                  src="/images/avatar/1.png"
                  alt="userimg"
                  className="flex object-scale-down w-12 rounded-full"
                />
              </Link> */}
                {/* <DropDownAcc /> */}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default withRouter(NavBar);
