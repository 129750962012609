import { useState } from "react";
import Paypal from "../payments/Paypal";

const Deposit = ({ setWallet }) => {
  const [amount, setAmount] = useState(null);
  return (
    <>
      <div className="md:col-span-12 md:mx-3 md:p-4 bg-white">
        <h1 className="text-3xl text-gray-900 text-center">
          Agrega fondos para poder ser joiner
        </h1>
        <div>
          <form className="text-center flex flex-col p-4 items-center">
            <label htmlFor="">Cantidad de fondos</label>
            <input
              onChange={(e) => setAmount(e.target.value)}
              type="number"
              step="0.01"
              className="bg-white p-4 w-3/6 text-gray-900 focus:outline-none my-5 border-b-2 focus:border-emerald-500 text-center"
              min="1"
              placeholder="100.00"
              value={amount}
            />
            {/* <input
              type="submit"
              className="focus:outline-none text-white p-4 w-2/5 font-semibold rounded bg-emerald-500 text-center my-3"
              value=""
            /> */}
            <Paypal
              amount={amount}
              setAmount={setAmount}
              setWallet={setWallet}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default Deposit;
