import { IoLogoWhatsapp } from "react-icons/io";
import { Link } from "react-router-dom";

import LoginFacebook from "./LoginFacebook";
import LoginGoogle from "./LoginGoogle";

import Joi from "joi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../actions/loginActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const emailErrors = Joi.string()
  .required()
  .trim()
  .lowercase()
  .not()
  .empty()
  .email({
    tlds: { allow: false },
  });

const passwordErrors = Joi.string().required().trim().not().empty();
const LoginForm = () => {
  const { push } = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [fieldsError, setFieldsError] = useState(false);

  const state = useSelector((state) => state.login);

  const dispatch = useDispatch();
  const dispatchLogin = (data) => dispatch(loginAction(data));

  const login = (e) => {
    e.preventDefault();
    if (passwordError || emailError) return setFieldsError(true);
    setFieldsError(false);
    dispatchLogin({
      email,
      password,
    });
  };
  const verifyField = (field) => {
    switch (field) {
      case "email":
        const { error: isEmail } = emailErrors.validate(email);
        if (isEmail) {
          return setEmailError(true);
        } else {
          return setEmailError(false);
        }
      case "password":
        const { error: isPassword } = passwordErrors.validate(password);

        if (isPassword) {
          return setPasswordError(true);
        } else {
          return setPasswordError(false);
        }

      default:
        break;
    }
  };

  useEffect(() => {
    if (!state.loading) {
      if (state.isLogged) {
        push("/home/full");
      }
    }
  }, [state.isLogged, state.loading]);

  return (
    <div className="p-5 md:w-full">
      {fieldsError ? (
        <div className="border-l-4 border-red-600 bg-red-400 m-2">
          <p className="p-4 text-center text-white font-bold flex flex-col">
            Error:
            <span className="text-gray-100 font-semibold">
              Porfavor llena los campos correctamente
            </span>
          </p>
        </div>
      ) : null}
      {state.error ? (
        <div className="border-l-4 border-red-600 bg-red-400 m-2">
          <p className="p-4 text-center text-white font-bold flex flex-col">
            Error:
            <span className="text-gray-100 font-semibold">
              {state.error.message}
            </span>
          </p>
        </div>
      ) : null}
      <h1 className="text-gray-800 font-medium text-3xl text-center">
        Continua con tu cuenta
      </h1>
      <div className="border-b-2 border-emerald-100 w-full my-4 " />
      {/* <LoginGoogle />
      <LoginFacebook /> */}
      <form onSubmit={(e) => login(e)}>
        <div
          style={{
            width: "100%",
            height: 20,
            borderBottom: "1.5px solid #999",
            textAlign: "center",
          }}
        >
          <span
            style={{
              fontSize: 18,
              backgroundColor: "#Fff",
              padding: "0 10px",
            }}
          >
            o
          </span>
        </div>
        <div>
          <div className="flex flex-col">
            <input
              type="email"
              placeholder="Direccion Email"
              className={`focus:border-emerald-400 hover:border-emerald-400  focus:outline-none border-b p-3 m-2 ${
                emailError ? "border-red-600 " : ""
              }`}
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              onBlur={() => verifyField("email")}
            />
            {emailError ? (
              <p className="text-center">
                <span className="text-red-500 text-sm">
                  Porfavor ingresa tu email correctamente
                </span>{" "}
              </p>
            ) : null}
            <input
              type="password"
              placeholder="Password"
              className={`focus:border-emerald-400 hover:border-emerald-400  focus:outline-none border-b p-3 m-2 ${
                passwordError ? "border-red-600 " : ""
              }`}
              onBlur={() => verifyField("password")}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordError ? (
              <p className="text-center">
                <span className="text-red-500 text-sm">
                  Porfavor ingresa tu contraseña correctamente
                </span>{" "}
              </p>
            ) : null}
            <input
              type="submit"
              value="Continuar"
              className="my-4 appearance-none focus:outline-none bg-emerald-500 text-white font-bold p-4 rounded-md cursor-pointer hover:bg-emerald-600"
            />
            <p>
              ¿No tienes una cuenta?
              <Link to="/register">
                {" "}
                <span className="text-emerald-500 font-normal hover:text-emerald-600 ">
                  Crea una
                </span>
              </Link>
            </p>
            <button
              type="submit"
              className="text-white my-4 bg-green-500 rounded-md focus:outline-none font-semibold flex justify-center items-center p-3"
            >
              <IoLogoWhatsapp className="mx-3" /> WhatsApp
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
