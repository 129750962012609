import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

const RedeemGiftCard = () => {
  const [code, setCode] = useState("");
  const history = useHistory();

  const authToken = window.localStorage.getItem("token");

  const submitHandler = async (e) => {
    e.preventDefault();

    const res = await axios.put(
      `${process.env.REACT_APP_API}giftcard/${code}/use`,
      {},
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    history.push("/wallet");
  };

  return (
    <div className="container p-10">
      <div className="bg-white px-20 py-10 flex justify-between">
        <p className="text-2xl font-bold">Canjear Gift Card</p>
      </div>

      <div className="w-full max-w-lg mx-auto mt-10">
        <form className="bg-white px-8 pt-6 pb-8 mb-4" onSubmit={submitHandler}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="value"
            >
              Código
            </label>
            <input
              className="shadow appearance-none border w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="value"
              type="text"
              placeholder="Código"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              min="1"
              required
            />

            <button
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 focus:outline-none focus:shadow-outline w-full"
              type="submit"
            >
              Canjear
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RedeemGiftCard;
