import React, { useState, useEffect } from "react";
import axios from "axios";
import Service from "./Service";

const ServicesList = () => {
  const [services, setServices] = useState([]);

  const authToken = window.localStorage.getItem("token");

  const getServices = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API}service`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    setServices(res.data.services);
  };

  useEffect(() => {
    getServices();
  }, []);

  if (!services) {
    return <h2 className="font-bold text-2xl">Cargando...</h2>;
  }

  return (
    <>
      {services.map((service) => (
        <Service data={service} key={service.id} />
      ))}
    </>
  );
};

export default ServicesList;
