import { loginTypes } from "../types/index";
const { LOGIN, LOGIN_ERROR, LOGIN_SUCCESS } = loginTypes;

export function loginAction(data) {
  return async (dispatch, _getState, { fetchApi }) => {
    dispatch(login(true));
    try {
      let res = await fetchApi.post("/auth/login", data);
      localStorage.setItem("token", res.data.token);
      dispatch(loginSuccess({ ...data, token: res.data.token }));
    } catch (e) {
      console.log(e.response.data.error);
      dispatch(loginError(e.response.data.error));
    }
  };
}

const login = (payload) => ({
  type: LOGIN,
  payload,
});

const loginSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});

const loginError = (payload) => ({
  type: LOGIN_ERROR,
  payload,
});
