import { Link } from "react-router-dom";

import { FaSearch } from "react-icons/fa";
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineCloseCircle, AiOutlineSearch } from "react-icons/ai";

const Nav = () => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <>
      {/* SideBar */}
      <div
        className={`w-full fixed md:hidden  flex-row bg-opacity-70 bg-gray-900 ${
          showMenu ? "flex" : "hidden"
        }`}
      >
        <div className="bg-gray-100 h-screen w-5/6">
          <div className="flex flex-col items-center">
            <img
              src="/images/logos/Logo-ES2-V2.png"
              alt="logo"
              className="flex object-scale-down w-52"
            />
          </div>
          <div className="flex flex-col gap-4 items-center">
            {/* <div class="flex items-center p-2 w-5/6 gap-3  bg-white rounded border-gray-100 border mx-auto">
              <label htmlFor="search">
                <AiOutlineSearch className="text-emerald-500 text-lg" />
              </label>
              <input
                name="search"
                id="search"
                type="text"
                placeholder="Busca entre los grupos"
                className="focus:outline-none placeholder-emerald-500 font-normal h-full text-emerald-500 w-full"
              />
            </div> */}
            <div className="flex flex-col gap-3 items-center">
              <Link>
                <p className="text-w font-semibold text-lg text-gray-700 hover:text-gray-800 uppercase">
                  Blog
                </p>
              </Link>
              <Link>
                <p className="text-w font-semibold text-lg text-gray-700 hover:text-gray-800 uppercase">
                  FAQ
                </p>
              </Link>
              <Link>
                <p className="text-w font-semibold text-lg text-gray-700 hover:text-gray-800 uppercase">
                  Login
                </p>
              </Link>
              <Link>
                <p className="text-w font-semibold text-lg text-gray-700 hover:text-gray-800 uppercase">
                  Registro
                </p>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex items-start mx-auto mt-5">
          <button
            className="focus:outline-none"
            onClick={() => setShowMenu(false)}
          >
            <AiOutlineCloseCircle className="text-gray-300 text-4xl" />
          </button>
        </div>
      </div>
      {/* Sidebar End ------------- */}
      <nav className="flex justify-between items-center flex-row max-w-6xl mx-auto md:p-0 p-2  ">
        <div className="flex">
          <div className="md:hidden flex justify-end flex-row items-center">
            <button onClick={() => setShowMenu(true)}>
              <GiHamburgerMenu className=" text-gray-200 text-2xl" />
            </button>
          </div>
          <img
            src="/images/logos/Logo-ES2-V1.png"
            alt="logo"
            className="flex object-scale-down w-52"
          />
        </div>
        <div className="flex items-center gap-4">
          <div className="gap-4 items-center justify-center md:flex hidden ">
            <div className="flex items-center">
              <p className="text-w font-semibold text-lg text-gray-300 hover:text-gray-100 uppercase">
                <FaSearch />
              </p>
            </div>
            <Link>
              <p className="text-w font-semibold text-lg text-gray-300 hover:text-gray-100 uppercase">
                Blog
              </p>
            </Link>
            <Link>
              <p className="text-w font-semibold text-lg text-gray-300 hover:text-gray-100 uppercase">
                FAQ
              </p>
            </Link>
          </div>

          <Link to="/login">
            <button className="bg-emerald-500 text-white px-6 py-1 shadow text-lg rounded-full cursor-pointer font-bold ">
              Login
            </button>
          </Link>
        </div>
      </nav>
    </>
  );
};

export default Nav;
