import { Link, useParams } from "react-router-dom";

const PostCheckoutSuccess = () => {
  const { service, id } = useParams();

  return (
    <>
      <div className="w-4/5 p-4 mx-auto min-h-screen mb-6">
        <div className="my-2 text-center">
          <h1 className="font-bold text-3xl text-gray-800">
            Petición enviada con éxito
          </h1>
          <p className="font-semibold text-xl text-gray-600">¿Qué sucede?</p>
          <p className="text-lg text-gray-700 font-semibold">
            Acción solicitada: introduce ahora los datos de pago, de esta marea
            se enviará tu cuota automáticamente en cuanto tu solicitud sea
            aceptada! Puedes estar tranquilo: en caso de que el Admin no acepte
            tu solicitud, no procesaremos ningún pago. Te recordamos que, en
            caso de problemas, tienes un periodo de 25 días para solicitar el
            reembolso de tu cuota.
          </p>
        </div>
        <Link to={`/post/${service}/${id}/payment`}>
          <button className="text-white bg-emerald-600 text-lg rounded px-4 py-1">
            Ve al pago
          </button>
        </Link>
      </div>
    </>
  );
};

export default PostCheckoutSuccess;
