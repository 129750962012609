import Groups from "../components/homefull/Groups";

const MyGroups = () => {
  return (
    <div className="w-5/6 mx-auto p-4 bg-white">
      <Groups />
    </div>
  );
};

export default MyGroups;
