import React, { useEffect, useState } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";

export const Menu = (groups) => {
  return groups?.map((item) => (
    <div
      key={item.id}
      className="w-64 mx-2 h-40 border focus:outline-none focus-within:outline-none appearance-none flex flex-row justify-between border-gray-100 rounded-lg cursor-pointer ps-3 dbg-emerald-400 bg-opacity-75 overflow-hidden"
    >
      <img
        src={item.imageUrl ? item.imageUrl : "/images/error.png"}
        alt=""
        className="object-cover cover-fill w-auto rounded-md overflow-hidden"
      />
      <div className="flex flex-col absolute justify-between gap-3 p-4 bg-gray-900 bg-opacity-80 h-40 rounded-md w-64 overflow-hidden">
        <div className="col-span-6 flex row items-center">
          <div className="flex flex-col">
            <p className="font-semibold text-white mr-9 uppercase">
              {item.serviceName}
            </p>
            <p className="text-white">
              <span className="font-semibold text-white">
                {item.joiningCost}
              </span>{" "}
              $ per month
            </p>
            <p className="text-white">
              <span className="font-semibold text-white">{item.freeSlots}</span>
              /{item.totalSlots} espacios libres
            </p>
          </div>
        </div>
        <div className="col-span-6 flex row items-center">
          <img
            src="https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-portrait-176256935.jpg"
            alt={item.admin.fullName}
            className="object-scale-down w-11 rounded-full bg-emerald-800"
          />
          <p className="ml-4 text-white">{item.admin.fullName}</p>
        </div>
      </div>
    </div>
  ));
};

const Service = ({ data }) => {
  const [groups, setGroups] = useState([]);
  const history = useHistory();

  const authToken = window.localStorage.getItem("token");

  const getGroupsByServiceId = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API}group?serviceId=${data.id}&offset=0&limit=4`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    setGroups(res.data.groups);
  };

  useEffect(() => {
    getGroupsByServiceId();
  }, []);

  const menu = Menu(groups);

  return (
    <div className="bg-white rounded-lg p-6 my-4">
      <h2 className="font-bold text-xl mb-6">{data.name}</h2>

      <ScrollMenu
        data={menu}
        onSelect={(id) =>
          history.push(
            `/post/${groups[0]?.serviceName
              .toLowerCase()
              .replaceAll(" ", "_")}/${id}`
          )
        }
        wrapperStyle={{
          width: "100%",
          marginBottom: "2.5rem",
        }}
        itemStyle={{
          border: "none",
          outline: "none",
        }}
        selected={(e) => console.log(e)}
      />

      <Link
        to={`/groups?service=${data.id}`}
        className="text-green-500 font-bold text-xl"
      >
        Ver más
      </Link>
    </div>
  );
};

export default Service;
