import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useAuth = (pathname) => {
  const [isLogged, setIsLogged] = useState(localStorage.getItem("token"));

  const [initLogin, setInitLogin] = useState(false);

  const { token } = useSelector((state) => state.login);

  useEffect(() => {
    console.log(pathname);
    (() => {
      if (token) return setIsLogged(true);
      if (isLogged) return;
      //console.log(isLogged);
      if (!initLogin) {
        setInitLogin(true);
        return;
      } else {
        return setIsLogged(false);
      }
    })();
  }, [initLogin, isLogged, token, pathname]);

  return {
    isLogged,
  };
};

export default useAuth;
