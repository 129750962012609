import { Link } from "react-router-dom";

const Plans = () => {
  return (
    <>
      <div className="items-center justify-center w-5/6 mx-auto">
        <h1 className="text-4xl font-semibold text-gray-800 text-center">
          ¿Cuáles son los planes multiusuario que puedes compartir?
        </h1>
        {/* Grupos */}
        <div className="md:flex md:flex-row col-12  mt-5">
          <div className="w-full rounded-md flex flex-col p-4 items-center justify-between my-5 col-span-4 ">
            <img
              src="/images/netflix.png"
              alt="netflix"
              className="object-scale-down red w-2/5"
            />
            <div className="flex flex-col justify-center mt-2 p-4 items-center">
              <div className="flex items-center flex-col">
                <strong className="text-red-600 text-center text-lg uppercase">
                  netflix premium
                </strong>
                <p className="text-center text-lg">
                  El coste compartido en grupo es de{" "}
                  <span className="font-semibold text-lg text-gray-800">
                    4 €/al mes
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="w-full rounded-md flex flex-col p-4 items-center justify-between my-5 col-span-4 ">
            <img
              src="/images/spotify.png"
              alt="netflix"
              className="object-scale-down red w-2/5"
            />
            <div className="flex flex-col justify-center mt-2 p-4 items-center">
              <div className="flex items-center flex-col">
                <strong className="text-green-600 text-center text-lg uppercase">
                  Spotify Family
                </strong>
                <p className="text-center text-lg">
                  El coste compartido en grupo es de{" "}
                  <span className="font-semibold text-lg text-gray-800">
                    2.5 €/al mes
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="w-full rounded-md flex flex-col p-4 items-center justify-between my-5 col-span-4 ">
            <img
              src="/images/disney-plus.png"
              alt="netflix"
              className="object-scale-down red w-2/5"
            />
            <div className="flex flex-col justify-center mt-2 p-4 items-center">
              <div className="flex items-center flex-col">
                <strong className="text-cyan-600 text-center text-lg uppercase">
                  Disney Plus
                </strong>
                <p className="text-center text-lg">
                  El coste compartido en grupo es de{" "}
                  <span className="font-semibold text-lg text-gray-800">
                    2.00 €/al mes
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Grupos end ------- */}
        <p className="text-center text-lg">
          <strong className=" text-gray-700">
            Invictus, Dropbox, Nintendo Switch y muchos otros planes
            Multiusuario y Familia son algunos de otros servicios pueden ser
            compartidos entre Familia, Compañeros de piso o trabajo y Amigos.
          </strong>
        </p>
        <div className="w-full flex flex-col items-center my-5">
          <Link to="/register">
            <button className="text-gray-100 rounded-full  border-emerald-300 shadow hover:text-white bg-emerald-500 p-4 text-lg font-bold">
              Registrate es gratis
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Plans;
