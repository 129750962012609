import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Groups = () => {
  const [groups, setGroups] = useState([]);
  const [offset, setOffset] = useState(0);
  const service = useQuery().get("service");
  const authToken = window.localStorage.getItem("token");

  const getGroups = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API}group?serviceId=${service}&offset=${offset}&limit=20`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    setGroups(res.data.groups);
  };

  useEffect(() => {
    getGroups();
  }, []);

  groups.map((group) => {
    console.log(group.id);
  });

  return (
    <div className="grid grid-cols-3 gap-4">
      {groups.map((group) => (
        <div
          key={group.id}
          className="w-64 mx-2 h-40 border focus:outline-none focus-within:outline-none appearance-none flex flex-row justify-between border-gray-100 rounded-lg cursor-pointer ps-3 dbg-emerald-400 bg-opacity-75 overflow-hidden"
        >
          <img
            src={group.imageUrl ? group.imageUrl : "/images/error.png"}
            alt=""
            className="object-cover cover-fill w-auto rounded-md overflow-hidden"
          />
          <div className="flex flex-col absolute justify-between gap-3 p-4 bg-gray-900 bg-opacity-80 h-40 rounded-md w-64 overflow-hidden">
            <div className="col-span-6 flex row items-center">
              <div className="flex flex-col">
                <p className="font-semibold text-white mr-9 uppercase">
                  {group.serviceName}
                </p>
                <p className="text-white">
                  <span className="font-semibold text-white">
                    {group.joiningCost}
                  </span>{" "}
                  $ per month
                </p>
                <p className="text-white">
                  <span className="font-semibold text-white">
                    {group.freeSlots}
                  </span>
                  /{group.totalSlots} espacios libres
                </p>
              </div>
            </div>
            <div className="col-span-6 flex row items-center">
              <img
                src="https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-portrait-176256935.jpg"
                alt={group.admin.fullName}
                className="object-scale-down w-11 rounded-full bg-emerald-800"
              />
              <p className="ml-4 text-white">{group.admin.fullName}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Groups;
