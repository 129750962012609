import { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import fetchApi from "../../services/fetchApi";

const CreateCustomComponent = () => {
  const { id } = useParams();

  const { push } = useHistory();

  const [service, setService] = useState({});

  const [relationshipType, setRelationshipType] = useState("Friends");
  const [visibility, setVisibility] = useState("Public");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    fetchApi(`service/${id}`).then((res) => setService(res.data.service));
  }, [id]);

  const createGroup = (e) => {
    e.preventDefault();
    var FormData = require("form-data");

    var data = new FormData();
    data.append("serviceId", service.id);
    data.append("relationshipType", relationshipType);
    data.append("renewalFrequency", "Monthly");
    data.append("acceptRequests", "Automatically");
    data.append("visibility", visibility);
    data.append("image", image);

    fetchApi
      .post("group/", data)
      .then((res) => {
        if ((res.data.message = "Grupo creado correctamente"))
          return push("/sharing");
      })
      .catch((e) => {
        console.log(e.response.data);
        localStorage.clear();
        return push("/login");
      });
  };
  return (
    <>
      <div className="w-1/2 bg-white p-5 border-gray-50 border shadow mx-auto my-5">
        <div className="bg-gray-300 h-52 w-10/12 rounded-md mx-auto my-3"></div>
        <form
          className=" my-6 mx-auto grid grid-cols-12 gap-4"
          onSubmit={(e) => createGroup(e)}
        >
          {/* <div className="col-span-6 flex flex-col">
            <label htmlFor="category">Categoria</label>
            <select
              name="category"
              id="category"
              className="focus:outline-none border-emerald-300 border-b p-4"
            >
              <option value="" disabled selected>
                --Selecciona una Categoria--
              </option>
              <option value="entertainment">Entretenimiento</option>
              <option value="job-work">Trabajo en equipo</option>
              <option value="apps">Aplicaiones</option>
              <option value="games">Juegos</option>
            </select>
          </div> */}
          <div className="col-span-12 flex flex-col">
            <label htmlFor="frequency">Frecuencia de la renovacion</label>
            <select
              name="frequency"
              id="frequency"
              className="focus:outline-none border-emerald-300 border-b p-4"
            >
              {/* <option value="" selected disabled>
                --Selecciona un periodo--
              </option>
              <option value="year" disabled>
                Anual
              </option> */}
              <option value="month" selected>
                Mensual
              </option>
            </select>
          </div>
          <div className="col-span-12">
            <label htmlFor="name">Nombre del servicio</label>
            <input
              id="name"
              type="text"
              className="focus:outline-none border-b border-emerald-300 p-4 w-full text-emerald-500 font-semibold"
              placeholder="Servicio personalizado"
            />
          </div>
          <div className="col-span-12">
            <label htmlFor="name">Breve descripcion del servicio</label>
            <input
              id="name"
              type="text"
              className="focus:outline-none border-b border-emerald-300 p-4 w-full text-emerald-500 font-semibold"
              placeholder="Descripcion del Servicio"
            />
          </div>
          <div className="col-span-6">
            <div className="flex flex-col mt-4">
              <label htmlFor="price">Precio total</label>
              <input
                type="number"
                id="price"
                className="focus:outline-none border-b border-emerald-300 text-emerald-500 p-4"
                placeholder="$ 0.0"
              />
            </div>
            <div className="flex flex-col mt-4">
              <label htmlFor="acceptation">Aceptacion de solicitudes</label>
              <select
                name="acceptation"
                id="acceptation"
                className="focus:outline-none border-emerald-300 border-b p-4 text-emerald-500 font-semibold"
              >
                {/* <option value="manual" >Manual</option> */}
                <option selected value="auto">
                  Automatica
                </option>
              </select>
            </div>
          </div>
          <div className="col-span-6">
            <div className="flex flex-col mt-4">
              <label htmlFor="stalls">Puestos libres</label>
              <input
                type="number"
                id="stalls"
                max="10"
                min="1"
                className="border-b border-emerald-300 p-4 focus:outline-none text-emerald-500 font-semibold"
              />
            </div>
            <div className="flex flex-col mt-4">
              <label htmlFor="acceptation">Tipo de relacion</label>
              <select
                name="acceptation"
                id="acceptation"
                className="focus:outline-none border-emerald-300 border-b p-4 text-emerald-500 font-semibold"
              >
                <option selected value="friends">
                  Amigos
                </option>
                <option value="domestic">Mismo nucleo domestico</option>
                <option value="family">Familia</option>
                <option value="jobFriends">Companeros de trabajo</option>
              </select>
            </div>
          </div>
          <div className="col-span-12 mt-4">
            <div className="flex flex-col w-full">
              <label htmlFor="visibility">Visibilidad</label>
              <select
                name="visibility"
                id="visibility"
                className="focus:outline-none border-emerald-300 border-b p-4 text-emerald-500 font-semibold"
              >
                <option selected value="public">
                  Publico
                </option>
                <option value="private">Privado</option>
              </select>
            </div>
            <div className="flex my-5">
              <p className="text-xs">
                Confirmo que entiendo que Together Price no está asociado o
                afiliado al servicio, que soy el titular de la cuenta de este
                servicio, y que he leído, entendido y acepto cumplir los
                términos y condiciones para compartir el servicio.
              </p>
              <input type="checkbox" className="border border-emerald-400" />
            </div>
            Nota: Este grupo será publicado solo después de ser aprobado por el
            equipo de Together Price
            <div className="flex flex-col mt-3 mx-auto">
              <button className="bg-emerald-500 text-md p-4 text-white rounded-md font-bold">
                Publicar
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateCustomComponent;
