import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import fetchApi from "../../services/fetchApi";

const CreateDefaultComponent = () => {
  const { id } = useParams();

  const { push } = useHistory();

  const [service, setService] = useState({});

  const [relationshipType, setRelationshipType] = useState("Friends");
  const [visibility, setVisibility] = useState("Public");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    fetchApi(`service/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }).then((res) => {
      setService(res.data.service);
    });
  }, [id]);

  const createGroup = (e) => {
    e.preventDefault();
    var FormData = require("form-data");

    var data = new FormData();
    data.append("serviceId", service.id);
    data.append("relationshipType", relationshipType);
    data.append("renewalFrequency", "Monthly");
    data.append("acceptRequests", "Automatically");
    data.append("visibility", visibility);
    data.append("image", image);

    fetchApi
      .post("group/", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (res.data.message === "Grupo creado correctamente")
          return push("/sharing");
      })
      .catch((e) => {
        localStorage.clear();
        push("/login");
        console.error(e.response.data);
      });
  };

  return (
    <>
      <div className="w-1/2 bg-white p-5 border-gray-50 border shadow mx-auto my-5">
        {preview && image ? (
          <>
            <img
              src={preview}
              alt="prev"
              className="object-scale-down w-full max-h-64"
            />
            <button
              className="bg-emerald-600 rounded-md shadow focus:outline-none p-4 text-center my-3 text-white "
              onClick={() => {
                setImage(null);
                setPreview(null);
              }}
            >
              Cambiar imagen
            </button>
          </>
        ) : (
          <>
            <label className="flex flex-col items-center px-4 py-6 h-52 w-10/12 mx-auto my-3 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue hover:text-blue-400 justify-center">
              <svg
                className="w-8 h-8"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
              </svg>
              <span className="mt-2 text-base leading-normal">
                Select a file
              </span>
              <input
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={(e) => {
                  setImage(e.target.files[0]);
                  setPreview(URL.createObjectURL(e.target.files[0]));
                }}
                required
                hidden
              />
            </label>
          </>
        )}

        <form
          className=" my-6 mx-auto grid grid-cols-12 gap-4"
          onSubmit={(e) => createGroup(e)}
        >
          {/* <div className="col-span-6 flex flex-col">
            <label htmlFor="category">Categoria</label>
            <select
              name="category"
              id="category"
              className="focus:outline-none border-emerald-300 border-b p-4 bg-gray-50"
            >
              <option value="" disabled defaultValue>
                --Selecciona una Categoria--
              </option>
              <option value="entertainment">Entretenimiento</option>
              <option value="job-work">Trabajo en equipo</option>
              <option value="apps">Aplicaiones</option>
              <option value="games">Juegos</option>
            </select>
          </div> */}
          <div className="col-span-12 flex flex-col">
            <label htmlFor="frequency">Frecuencia de la renovacion</label>
            <select
              name="frequency"
              id="frequency"
              className="focus:outline-none border-emerald-300 border-b p-4 bg-gray-50"
              disabled
            >
              {/* <option value="" defaultValue disabled>
                --Selecciona un periodo--
              </option>
              <option value="year">Anual</option> */}
              <option value="month" defaultValue>
                Mensual
              </option>
            </select>
          </div>
          <div className="col-span-12">
            <label htmlFor="name">Nombre del servicio</label>
            <input
              id="name"
              type="text"
              className="focus:outline-none border-b border-emerald-300 p-4 w-full text-emerald-500 font-semibold"
              defaultValue={service.name}
              disabled
            />
          </div>
          <div className="col-span-12">
            <label htmlFor="name">Breve descripcion del servicio</label>
            <input
              id="description"
              type="text"
              className="focus:outline-none border-b border-emerald-300 p-4 w-full text-emerald-500 font-semibold"
              defaultValue={service.description}
              disabled
            />
          </div>
          <div className="col-span-6">
            <div className="flex flex-col mt-4">
              <label htmlFor="price">Precio total</label>
              <input
                type="number"
                id="price"
                className="focus:outline-none border-b border-emerald-300 text-emerald-500 p-4"
                defaultValue={service.price}
                disabled
              />
            </div>
            <div className="flex flex-col mt-4">
              <label htmlFor="acceptation">Aceptacion de solicitudes</label>
              <select
                name="acceptation"
                id="acceptation"
                className="focus:outline-none border-emerald-300 border-b p-4 text-emerald-500 font-semibold bg-gray-50"
                disabled
              >
                {/* <option value="manual">Manual</option> */}
                <option defaultValue value="Automatically">
                  Automatica
                </option>
              </select>
            </div>
          </div>
          <div className="col-span-6">
            <div className="flex flex-col mt-4">
              <label htmlFor="stalls">Puestos libres</label>
              <input
                type="number"
                id="stalls"
                max="10"
                min="1"
                disabled
                defaultValue={service.totalSlots}
                className="border-b border-emerald-300 p-4 focus:outline-none text-emerald-500 font-semibold"
              />
            </div>
            <div className="flex flex-col mt-4">
              <label htmlFor="acceptation">Tipo de relacion</label>
              <select
                name="relation"
                id="relation"
                className="focus:outline-none border-emerald-300 border-b p-4 text-emerald-500 font-semibold bg-gray-50"
                onChange={(e) => setRelationshipType(e.target.value)}
              >
                <option defaultValue value="Friends">
                  Amigos
                </option>
                {/* <option value="">Mismo nucleo domestico</option> */}
                <option value="Family">Familia</option>
                <option value="Colleagues">Companeros de trabajo</option>
              </select>
            </div>
          </div>
          <div className="col-span-12 mt-4">
            <div className="flex flex-col w-full">
              <label htmlFor="visibility">Visibilidad</label>
              <select
                onChange={(e) => setVisibility(e.target.value)}
                name="visibility"
                id="visibility"
                className="focus:outline-none border-emerald-300 border-b p-4 text-emerald-500 font-semibold bg-gray-50"
              >
                <option defaultValue value="Public">
                  Publico
                </option>
                <option value="Private">Privado</option>
              </select>
            </div>
            <div className="flex my-5">
              <p className="text-xs">
                Al continuar confirmo que entiendo que ES2 no está asociado o
                afiliado al servicio, que soy el titular de la cuenta de este
                servicio, y que he leído, entendido y acepto cumplir los
                términos y condiciones para compartir el servicio.
              </p>
              {/* <input type="checkbox" className="border border-emerald-400" /> */}
            </div>
            Nota: Este grupo será publicado solo después de ser aprobado por el
            equipo de ES2
            <div className="flex flex-col mt-3 mx-auto">
              <button className="bg-emerald-500 text-md p-4 text-white rounded-md font-bold">
                Publicar
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateDefaultComponent;
