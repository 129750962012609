import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//Routes Pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import HomeFull from "./pages/HomeFull";
import Create from "./pages/Create";
//import CreateComponent from "./components/create/CreateComponent";
import Post from "./pages/Post";
import NavBar from "./components/layout/NavBar";
import MyGroups from "./pages/MyGroups";
import PostCheckout from "./pages/PostCheckout";
import PostCheckoutSuccess from "./pages/PostCheckoutSuccess";
import PostPayment from "./pages/PostPayment";
import HOC from "./components/auth/HOC";
import CreateDefaultComponent from "./components/create/CreateDefaultComponent";
import CreateCustomComponent from "./components/create/CreateCustomComponent";
import Wallet from "./pages/Wallet";
import GiftCards from "./pages/GiftCards";
import GenerateGiftCard from "./pages/GenerateGiftCard";
import RedeemGiftCard from "./components/giftcards/RedeemGiftCard";
import Groups from "./pages/Groups";

function App() {
  return (
    <Router>
      <div className="bg-gray-50">
        <NavBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />

          <HOC>
            <Route exact path="/home/full" component={HomeFull} />
            <Route exact path="/create" component={Create} />
            <Route exact path="/sharing" component={MyGroups} />
            <Route
              exact
              path="/create/:slug/:id"
              component={CreateDefaultComponent}
            />
            <Route
              exact
              path="/create/custom"
              component={CreateCustomComponent}
            />
            <Route exact path="/post/:service/:id" component={Post} />
            <Route
              exact
              path="/post/:service/:id/checkout"
              component={PostCheckout}
            />
            <Route
              exact
              path="/post/:service/:id/checkout/success"
              component={PostCheckoutSuccess}
            />
            <Route
              exact
              path="/post/:service/:id/payment"
              component={PostPayment}
            />
            <Route exact path="/giftcards" component={RedeemGiftCard} />
            <Route exact path="/groups" component={Groups} />

            {/* <Route exact path="/giftcards/generate" component={GenerateGiftCard} /> */}
            <Route exact path="/wallet" component={Wallet} />
          </HOC>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
